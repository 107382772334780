
export class FactCheck {
  constructor(options = {}) {
    this.statements = options.statements || [];
    this.onComplete = options.onComplete || (() => {});
    this.onClose = options.onClose || (() => {});
    this.onExplanation = options.onExplanation || (() => {});
    
    this.title = options.title || "";
    this.stats = options.stats;

    this.currentIndex = 0;
    this.progress = [];
    this.times = [];
    this.gameStartTime = Date.now();
    this.questionStartTime = Date.now();
    
    this.flipCount = 0;
    this.currentSide = 'front';
    this.isAnimating = false;

    this.suddenDeath = options.suddenDeath !== undefined ? options.suddenDeath : true;

    if (options.results) {
      const { progress, totalTime, questionTimes } = options.results;
      this.progress = progress || [];  // Use the stored progress array
      this.times = questionTimes || [];
      this.gameStartTime = Date.now() - totalTime;
    }


    this.initialize();
  }

  initialize() {
    this.modalContainer = document.createElement('div');
    this.modalContainer.className = 'fc-modal-container';
    
    this.modalOuterWrapper = document.createElement('div');
    this.modalOuterWrapper.className = 'fc-modal-outer-wrapper';

    this.modalWrapper = document.createElement('div');
    this.modalWrapper.className = 'fc-modal-wrapper';

    this.modalWrapper.setAttribute('data-flip-count', '0');
    
    this.modalFront = this.createModal();
    this.modalBack = this.createModal();
    
    this.modalFront.classList.add('fc-modal-front');
    this.modalBack.classList.add('fc-modal-back');
    
    this.modalWrapper.appendChild(this.modalFront);
    this.modalWrapper.appendChild(this.modalBack);

    this.closeButton = document.createElement('button');
    this.closeButton.className = 'fc-close-button';
    this.closeButton.innerHTML = '×';
    this.closeButton.onclick = () => this.close();
    
    this.modalOuterWrapper.appendChild(this.closeButton);
    this.modalOuterWrapper.appendChild(this.modalWrapper);
    this.modalContainer.appendChild(this.modalOuterWrapper);

    document.body.appendChild(this.modalContainer);

    this.modalWrapper.addEventListener('transitionend', (e) => {
      if (e.propertyName === 'transform' || e.propertyName === 'opacity') {
        this.isAnimating = false;
        const buttons = document.querySelectorAll('.fc-button');
        buttons.forEach(button => button.disabled = false);
      }
    });

    // If we have progress from results, go straight to game over
    if (this.progress.length > 0) {
      this.gameOver(true);
    } else {
      this.renderQuestion(this.modalFront);
      this.modalContainer.addEventListener('click', this.handleClick.bind(this));
    }
  }

  handleClick(event) {
    const elements = document.elementsFromPoint(event.clientX, event.clientY);
    const button = elements.find(el => el.classList.contains('fc-button'));
  
    if (button) {
      event.preventDefault();
      if (button.classList.contains('fc-true')) {
        this.handleAnswer(true);
      } else if (button.classList.contains('fc-false')) {
        this.handleAnswer(false);
      }
    }
  }

  renderQuestion(modalElement) {
    const currentQuestion = this.statements[this.currentIndex];
    const content = modalElement.querySelector('.fc-content');
    
    modalElement.querySelector('.fc-progress-bar').style.display = 'none';
    
    const formattedQuestion = currentQuestion.question.replace(
        /\*\*(.*?)\*\*/g, 
        '<strong>$1</strong>'
    );
    
    content.innerHTML = `
    <div class="fc-question-counter">${this.currentIndex + 1}/${this.statements.length}</div>
    <div class="fc-statement"><span>${formattedQuestion}</span></div>
    <div class="fc-buttons">
      <button class="fc-button fc-true"><span class="fc-checkmark">&#x2713;</span> True</button>
      <button class="fc-button fc-false">&#x274C; False</button>
    </div>
  `;
  }
  handleAnswer(answer) {
    if (this.isAnimating) return;
    
    const buttons = document.querySelectorAll('.fc-button');
    buttons.forEach(button => button.disabled = true);
    
    this.recordTime();
    const currentQuestion = this.statements[this.currentIndex];
    const isCorrect = String(answer).toLowerCase() === String(currentQuestion.correct).toLowerCase();
        
    if (currentQuestion.comment) {
      this.onExplanation({
        question: currentQuestion.question,
        userAnswer: answer,
        isCorrect,
        comment: currentQuestion.comment
      });
    }
    
    this.progress.push(isCorrect);
    this.updateProgressBar();
    
    const isLastQuestion = this.currentIndex === this.statements.length - 1;
    const shouldEndGame = (this.suddenDeath && !isCorrect) || isLastQuestion;
  
    const proceedToNext = () => {
      if (shouldEndGame) {
        this.gameOver(); // isLastQuestion && isCorrect);
        return;
      }
  
      // Continue to next question
      this.isAnimating = true;
      this.currentIndex++;
      
      this.currentSide = this.currentSide === 'front' ? 'back' : 'front';
      const nextCard = this.currentSide === 'front' ? this.modalFront : this.modalBack;
      
      this.flipCount++;
      const rotation = this.flipCount * -180;
      this.modalWrapper.style.transform = `rotateY(${rotation}deg)`;
      this.modalWrapper.setAttribute('data-flip-count', this.flipCount % 2);
  
      const outerWrapper = this.modalOuterWrapper;
      outerWrapper.classList.add('wobble-flip');
      
      setTimeout(() => {
          outerWrapper.classList.remove('wobble-flip');
      }, 600);

      this.renderQuestion(nextCard);
    };
    
    if (!isCorrect) {
      // Handle incorrect answer with shake
      const outerWrapper = this.modalOuterWrapper;
      outerWrapper.classList.add('shake');
      
      // Wait for shake animation to complete before proceeding
      setTimeout(() => {
        outerWrapper.classList.remove('shake');
        if (!shouldEndGame) {
          proceedToNext();
        } else {
          this.gameOver();
        }
      }, 500); // Match this to your shake animation duration
    } else {
      // Correct answer - proceed immediately
      proceedToNext();
    }
  }


  createModal() {
    const modal = document.createElement('div');
    modal.className = 'fc-modal';
    
    const progressBar = document.createElement('div');
    progressBar.className = 'fc-progress-bar';
    progressBar.style.display = 'none';
    
    const content = document.createElement('div');
    content.className = 'fc-content';
    
    modal.appendChild(content);
    modal.appendChild(progressBar);
    
    return modal;
  }

// In the FactCheck class, update the updateProgressBar method:

updateProgressBar() {
  [this.modalFront, this.modalBack].forEach(modal => {
    const progressBar = modal.querySelector('.fc-progress-bar');
    progressBar.innerHTML = '';
    const maxTime = Math.max(...this.times) || 0;
    
    for (let i = 0; i < this.statements.length; i++) {
      const indicator = document.createElement('div');
      indicator.className = 'fc-progress-indicator';
      
      if (i < this.progress.length) {
        // Create wrapper for indicator and time bar
        const wrapper = document.createElement('div');
        wrapper.className = 'fc-indicator-wrapper';
        
        // Time visualization bar
        const timeBar = document.createElement('div');
        timeBar.className = 'fc-time-bar';
        const timeHeight = (this.times[i] / maxTime) * 100;
        timeBar.style.height = `${timeHeight}%`;
        
        // Main indicator dot
        const dot = document.createElement('div');
        dot.className = 'fc-dot';
        
        // Apply correct/incorrect classes to dot
        if (this.progress[i] === true) {
          dot.classList.add('fc-user-correct');
        } else {
          dot.classList.add('fc-user-incorrect');
        }
        
        // Tooltip with time info
        const tooltip = document.createElement('div');
        tooltip.className = 'fc-tooltip';
        tooltip.textContent = `${Math.floor(this.times[i] / 1000)}s`;
        
        // Assembly
        wrapper.appendChild(timeBar);
        wrapper.appendChild(dot);
        wrapper.appendChild(tooltip);
        indicator.appendChild(wrapper);
      } else {
        // Future questions get empty dots
        const dot = document.createElement('div');
        dot.className = 'fc-dot fc-future';
        indicator.appendChild(dot);
      }
      
      progressBar.appendChild(indicator);
    }
  });
}

  recordTime() {
    const timeSpent = Date.now() - this.questionStartTime;
    this.times.push(timeSpent);
    this.questionStartTime = Date.now();
  }
 

  gameOver(displayOnly) {
    this.isAnimating = true;
    console.log( this.progress );
    const result = {
      correctAnswers: this.progress.filter(p => p === true).length,
      questionTimes: this.times,
      statements: this.statements.length,
      progress: this.progress  // Add this line to store the actual progress array
    };
    
    if (! displayOnly) {
      this.onComplete(result);
    }

    // Define histogram dots function - for question times
    console.log(this.times);
    const maxTime = Math.max(...this.times.filter(t => Number.isFinite(t)))/1000 || 1; // fallback to 1 to avoid division by zero

    const getHistogramDots = (timeMs, total) => {

    //  console.log(timeMs, total, maxTime);
      const proportionalDots = Math.ceil((timeMs / total) * 14);
      const maxPossibleDots = Math.round(timeMs);
      const dotCount = Math.min(proportionalDots, maxPossibleDots);
      const dots = '·'.repeat(dotCount);
      const spaces = ' '.repeat(14 - dotCount);
      return dots + spaces;
    };

    // Handle the regular game over card flip first
    const nextCard = this.currentSide === 'front' ? this.modalBack : this.modalFront;
    const content = nextCard.querySelector('.fc-content');
  
    // Create container for both cards
    const gameOverContainer = document.createElement('div');
    gameOverContainer.className = 'fc-game-over-container';
    
    // Add game-over class to modal wrapper for positioning
    this.modalWrapper.classList.add('game-over');
    
    console.log('adding the HTML');

    // Regular card content
    content.innerHTML = `
      <div class="fc-receipt">
        <div class="fc-receipt-header">
          <h2>FACT CHECK</h2>
          <div class="fc-receipt-id">${this.title}</div>
        </div>
  
        <div class="fc-receipt-score">
          <div class="fc-score-num">${result.correctAnswers}/${this.statements.length}</div>
          <div class="fc-score-label">CORRECT ANSWERS</div>
        </div>
  
        <div class="fc-receipt-timeline">
          ${this.progress.map((correct, i) => `
            <div class="fc-timeline-row">
              <span class="fc-q-num">#${String(i + 1).padStart(2, '0')}</span>
              <span class="fc-q-result ${correct ? 'fc-q-correct' : 'fc-q-wrong'}">
                ${correct ? '✓' : '×'}
              </span>
              <span class="fc-dots">${getHistogramDots(this.times[i]/1000, maxTime)}</span>
              <span class="fc-q-time">${Math.round(this.times[i] / 1000)}s</span>
            </div>
          `).join('')}
        </div>
  
        <div class="fc-receipt-footer">
          ∙∙∙∙∙∙∙∙∙∙∙∙∙∙∙∙∙∙
          <br>
          THANK YOU FOR PLAYING
          <br>
          QAIZ.APP
        </div>
      </div>
    `;
  
    // Stats card
    const statsCard = document.createElement('div');
    statsCard.className = 'fc-stats-modal';
    
    console.log('now doing stats');

    

    this.stats.then(stats => {
      const userScore = result.correctAnswers;
      const totalQuestions = this.statements.length;
      
      console.log(stats);

      // Create array of all possible scores
      const allScores = Array.from({ length: totalQuestions + 1 }, (_, i) => ({
        score: i,
        count: 0,
        percentage: '0.0%'
      }));
      
      if (! displayOnly) {
        stats.totalParticipants = stats.totalParticipants ? stats.totalParticipants+1 : 1;
      }
  
      // Fill in actual scores
      if (Array.isArray(stats.scores) && stats.scores.length > 0) {
          stats.scores.forEach(stat => {
              // Also add safety checks for the stat object and its properties
              if (stat && typeof stat.score === 'number' && typeof stat.count === 'number') {
                  allScores[stat.score] = {
                      score: stat.score,
                      count: stat.count,
                      percentage: ((stat.count / (stats.totalParticipants || 1)) * 100).toFixed(1) + '%'
                  };
              }
          });
      }

      if (! displayOnly) {
        allScores[userScore].count++;
      }

      // Find the maximum count
      const maxCount = Math.max(...allScores.map(score => score.count));

      
      statsCard.innerHTML = `
      <div class="fc-receipt">
        <div class="fc-receipt-header">
          <h2>GLOBAL SCORES</h2>
          <div class="fc-receipt-id">${stats.totalParticipants.toLocaleString()} TOTAL ATTEMPTS</div>
        </div>
                  
        <div class="fc-stats-rows">
            <div class="fc-timeline-row">
Correct</div>
          ${allScores.map(stat => `
            <div class="${stat.score === userScore ? 'fc-timeline-row-bold' : 'fc-timeline-row'}">
              <span class="fc-q-num">${String(stat.score).padStart(2, ' ')}</span>
              <span class="fc-dots">${getHistogramDots(stat.count, maxCount)}</span>
              <span class="fc-q-time">${stat.count || ''}</span>
              ${stat.score === userScore ? ' <= YOU' : ''}
            </div>
          `).join('')}
        </div>

        <div class="fc-stats-summary">
        ∙∙∙∙∙∙∙∙∙∙∙∙∙∙∙∙∙∙

        <div class="fc-stat-row">
            <span class="fc-stat-label">Average Score</span>
            <span class="fc-stat-value">${(allScores.reduce((acc, s) => acc + (s.score * s.count), 0) / stats.totalParticipants).toFixed(1)}</span>
        </div>
        <div class="fc-stat-row">
            <span class="fc-stat-label">Your Percentile</span>
            <span class="fc-stat-value">${calculatePercentile(userScore, allScores)}%</span>
        </div>
        <div class="fc-stat-row">
            <span class="fc-stat-label">Perfect Scores</span>
            <span class="fc-stat-value">${((allScores[totalQuestions]?.count || 0) / stats.totalParticipants * 100).toFixed(1)}%</span>
        </div>
        </div>
        <div class="fc-receipt-footer">
          
        </div>
      </div>
    `;
    });

    // Move the modal wrapper into the game over container and add stats card
    gameOverContainer.appendChild(this.modalWrapper);
    gameOverContainer.appendChild(statsCard);
    
    // Replace the content in the outer wrapper
    this.modalOuterWrapper.appendChild(gameOverContainer);

// Perform the final card flip
    this.flipCount++;
    const flipClass = this.flipCount % 2 === 0 ? 'flip-even' : 'flip-odd';
    this.modalWrapper.classList.remove('flip-even', 'flip-odd');
    this.modalWrapper.style.transform = ``;
    this.modalWrapper.classList.add(flipClass);

  this.modalWrapper.setAttribute('data-flip-count', this.flipCount % 2);
  
    setTimeout(() => {
      this.isAnimating = false;
    }, 600);
  }
  
  reset() {
    this.currentIndex = 0;
    this.progress = [];
    this.times = [];
    this.gameStartTime = Date.now();
    this.questionStartTime = Date.now();
    this.flipCount = 0;
    this.currentSide = 'front';
    this.modalWrapper.setAttribute('data-flip-count', 0);
    this.updateProgressBar();
    this.renderQuestion(this.modalFront);
  }

  close() {
    this.modalContainer.classList.add('closing');
    this.modalWrapper.classList.add('closing');
    
    setTimeout(() => {
      this.modalContainer.remove();
      this.onClose();
    }, 300);
  }

}

function calculatePercentile(userScore, allScores) {
  // Calculate total participants from actual data
  const totalParticipants = allScores.reduce((sum, s) => sum + s.count, 0);
  
  // Count scores below user's score
  const belowUser = allScores
      .filter(s => s.score < userScore)
      .reduce((sum, s) => sum + s.count, 0);
  
  // Get count at user's score
  const atUserScore = allScores[userScore]?.count || 0;
  
  // Include half of the people with the same score as the user
  // This gives a more accurate percentile representation
  return 100 - Math.round((belowUser + (atUserScore / 2)) / totalParticipants * 100);
}


  